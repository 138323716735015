import './Footer.css'
import JohnCube from './johncube.svg'



function Footer() {
    return (
        <div id="footer">
            <div id="footer-content">
                <div id="logo">
                    <img src={JohnCube}></img>
                    <a href="https://johncube.pl" target="_blank">JohnCube<br/><span>2025</span></a>
                </div>
            </div>
            
            
        </div>
    );
  }
  
  export default Footer;